.is-invisible {
    display: none;
    visibility: hidden;
}
.overlay {
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 999;

    background: #000;
    opacity: 0.6;
}
.centered {
    left: 50%;
    position: absolute;
    top: 50%;
    z-index: 9999;

    -webkit-transform: translate(-50%, -50%);
    /*-moz-transform: translate(-50%, -50%);*/
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.loader--modal {
    height: 64px;
    left: 50%;
    margin-left: -32px;
    margin-top: -32px;
    position: fixed;
    top: 50%;
    width: 64px;
    z-index: 9999;

    background: #191919 url(/images/loader.gif) center center no-repeat;
    border-radius: 10px;
}